import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

export default function App(props) {
  let theme = createTheme({
    palette: {
      // mode: "dark",
      primary: {
        // light: "#757ce8",
        main: "#ee6c4d",
        // dark: "#002884",
        // contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: "Poppins,sans-serif",
      h1: {
        fontWeight: "bold",
      },
      h3: {
        fontWeight: "bold",
      },

      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: "bold",
      },
      overline: {
        lineHeight: "unset",
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: "h2",
            h2: "h2",
            h3: "h2",
            h4: "h2",
            h5: "h2",
            h6: "h2",
            subtitle1: "h2",
            subtitle2: "h2",
            body1: "p",
            body2: "p",
            overline: "p",
            caption: "p",
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>{props.children}</StyledEngineProvider>
    </ThemeProvider>
  );
}
