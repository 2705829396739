import React from "react";
import axios from "axios";
export default React.createContext();

// axios.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("AuthToken");

//     if (token) {
//       config.headers = {
//         authorization: `Bearer ${token}`,
//         Accept: "application/json",
//         // "Content-Type": "application/json",
//         // credentials: "same-origin",
//       };
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const config = error.config;

//     if (error.response.status === 401 && !config._retry) {
//       localStorage.clear();
//       window.location.href = "/login";
//     }

//     return Promise.reject(error);
//   }
// );
