import React from "react";
import ReactDOM from "react-dom";
import "assets/css/app.css";
import AppRoute from "route/route";
import ThemeProvider from "provider/theme";
import NetworkProvider from "provider/network";

ReactDOM.render(
  <NetworkProvider>
    <ThemeProvider>
      <AppRoute />
    </ThemeProvider>
  </NetworkProvider>,
  document.getElementById("root")
);
