import { Box, Modal, CircularProgress } from "@mui/material";

export default function OverlayScreen() {
  return (
    <Modal open>
      <Box width="100vw" height="100vh" className="center" zIndex="tooltip">
        <CircularProgress color="inherit" />
      </Box>
    </Modal>
  );
}
