import Overlay from "component/screenHandle/overlay";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

const Notfound = lazy(() => import("view/404"));

export default function Nav() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Overlay />}>
        <Switch>
          <Route path="/" exact component={lazy(() => import("view/home"))} />
          {/* <Route path="/" exact component={lazy(() => import("view/move"))} /> */}
          <Route path="/new" exact component={lazy(() => import("view/profile"))} />
          <Route path="/cv" exact component={lazy(() => import("view/cv"))} />

          <Route path="/dashboard" exact component={lazy(() => import("view/personal/chrome"))} />
          <Route path="/cvprint" exact component={lazy(() => import("view/cvprint"))} />

          <Route path="/typograph" exact component={lazy(() => import("view/typography"))} />
          <Route path="/iosblur" exact component={lazy(() => import("view/iosblur"))} />
          <Route path="/personal" component={PersonalRoutes} />
          <Route path="/poc" component={ExampleRoutes} />
          <Route path="*" component={Notfound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

const ExampleRoutes = () => {
  return (
    <Switch>
      <Route path="/poc/draw" exact component={lazy(() => import("view/example/draw/index"))} />
      <Route path="/poc/draw/ex1" component={lazy(() => import("view/example/draw/ex1"))} />

      <Route path="/poc/draw/ex2" component={lazy(() => import("view/example/draw/ex2"))} />

      <Route path="/poc/draw/ex4" component={lazy(() => import("view/example/draw/ex4"))} />

      <Route path="/poc/colorthemes" component={lazy(() => import("view/example/colorTheme"))} />
    </Switch>
  );
};

const PersonalRoutes = () => {
  return (
    <Switch>
      <Route path="/personal" exact component={lazy(() => import("view/personal/dashboard"))} />
      <Route path="/personal/chrome" exact component={lazy(() => import("view/personal/chrome"))} />
    </Switch>
  );
};
