import React, { useState } from "react";
import Context from "./context";
import Loader from "component/screenHandle/overlay";

export default function App(props) {
  const [isLoading, setisLoading] = useState(false);

  return (
    <Context.Provider
      value={{
        isLoading,
        setisLoading,
      }}
    >
      {isLoading && <Loader />}
      {props.children}
    </Context.Provider>
  );
}
